import React, { Fragment } from 'react';
import super1 from "../assets/rekha.png";
import super2 from "../assets/anupama.png";
import super3 from "../assets/dhiren.png";
import MindminesComponent from '../components/Mindmines';
import Team from '../components/Team';
import Footer from '../components/UI/Footer';

const data = [
    {
      image: super1,
      title: "Dr. Rekha Singh",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      mail: "director@westinbridge.com",
      linkedin: "https://www.linkedin.com/in/rekha---singh"
    },
    {
      image: super2,
      title: "Dr. Anupama Verma",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      mail: "support@mindmines.org"
    },
    {
      image: super3,
      title: "Mr. Dhiren Singh",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      mail: "director@westinbridge.com",
      linkedin: "https://www.linkedin.com/in/dhiren-singh-6b8400b/"
    },
  ];

function Mindmines(props) {
    return (
        <Fragment>
            <MindminesComponent currentTheme={props.currentTheme} />
            <Team currentTheme={props.currentTheme} data={data} />
            <Footer currentTheme={props.currentTheme}/>
        </Fragment>
    );
}

export default Mindmines;