import Card from "./UI/Cards";
import super1 from "../assets/rekha.png";
import release2 from "../assets/dhiren.png";
import classes from "./Journey.module.css";
import { useMediaQuery } from "@mui/material";

const Journey = props => {
  const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
  return (
    <main className={classes.free}>
      <div className={classes.container}>
        <div className={classes.background}>
          <div className={`${classes.ellipse} ${classes.pink}`}></div>
          <div className={`${classes.ellipse} ${classes.green}`}></div>
        </div>
        <div className={classes.content}>
          <div className={classes.image}>
            <h1 className={classes.title}>
              <span className={classes.line}></span> <span className={classes.heading}>OUR JOURNEY</span><span className={classes.line}></span>
            </h1>
          </div>
          <p className={classes.description}><strong>Westinbridge Consulting  Private Limited</strong> was founded in 2019 by us, a husband-wife team of corporate professionals, Dr. Rekha Singh and Dhiren Singh with about 25yrs of diverse work experience. Our many years of international corporate stints have given us the opportunities of having dealt with and worked with people from almost every major nationality in the world. For both of us, this was perhaps the best part of working overseas. Having experienced first-hand the many advantages and challenges of cross-functional teams, the adventures of being an expat family, and being mentors to young expats from different countries, we developed a common passion for nurturing youth, and fostering cross-cultural connections. We returned to India in 2019, after 14years of working in different continents. On our return, we chose to embark on a personally enriching professional journey, albeit of a different kind than the one we have been used to. Back home, but out of our comfort zones.</p>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column" }}>
            <div style={{ marginRight: "1.5rem", width:isNonMobileScreens?"25%":"100%", textAlign:isNonMobileScreens?"right":"left" }}>
              <h2 style={{ margin: 0, fontSize: "2.1rem", marginTop:"0.5rem" }}>CORPORATE</h2>
              <h2 style={{ color: "#ff8139", margin: 0, fontSize: "2.1rem", marginBottom:"0.5rem" }}>PROFESSIONALS</h2>
              <h2 style={{ margin: 0, fontSize: "2.1rem" }}>ANGEL</h2>
              <h2 style={{ color: "#ff8139", margin: 0, fontSize: "2.1rem" }}>INVESTORS</h2>
            </div>
            <div style={{ display: "flex", flexDirection: "column", width:isNonMobileScreens?"75%":"100%" }}>
              {/* <h3 style={{ color: "#ff8139", textAlign: "center" }}>WHO WE ARE</h3> */}
              <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column" }}>
                <p style={{ paddingRight: "1.5rem", margin:"0.2rem", width:isNonMobileScreens?"73%":"100%" }}>We started off Initially as angel investors - what we believed provided the ideal platform for engaging with youth, and a great way to invest in them and nurture them  But soon, this paved the way for an even better path, one that could make a real social impact in the lives of young expatriates and those seeking to build a life away from home. So, we decided to take on the challenge and set out on this exciting journey to build an ecosystem of cross-cultural inclusion by forging tie ups with international social groups and bringing them together on a common platform to support one another and be unified in purpose.   </p>
                <div style={{marginTop:"2.5rem"}}>
                  <h1 style={{ margin: 0, textAlign: "left", fontSize: "2.7rem" }}>VENTURE</h1>
                  <h1 style={{ color: "#ff8139", textAlign: "left", margin: 0, fontSize: "2.7rem" }}>BUILDERS</h1>
                </div>
              </div>
            </div>
          </div>
              <p style={{ marginBottom: "0.5rem", marginTop:"0.5rem" }}>Driven by a commitment to fostering diversity, inclusion and youth empowerment, Westinbridge Consulting  Private Limited strives to redefining the way we connect and thrive in a globalized world. We're not just a company; we're a community, a global village where the boundaries of culture are meant to connect one with the other, not create islands of isolation.   </p>
          <div style={{ display: "flex", marginTop: "1rem" }}>

              <h1 className={classes.title}>
                <span className={classes.line2}></span> <span className={classes.heading2}>Experience the Journey with Us</span><span className={classes.line2}></span>
              </h1>

          </div>
        </div>
      </div>
      {isNonMobileScreens && <div className={classes.cards}>
        <div className={classes.card1}>
          <Card image={super1} title="Rekha Singh" price={2.99} tag={12983} time={1} currentTheme={props.currentTheme} free={true} />
        </div>
        <div className={classes.card2}>
          <Card image={release2} title="Dhiren Singh" price={3.95} tag="1094" time={2} currentTheme={props.currentTheme} free={true} />
        </div>
      </div>}
    </main>
  );
}

export default Journey;