import React from "react";
import { BsArrowRight } from "react-icons/bs";
import classes from "./Zuhaus.module.css";
import zuhaus from "../assets/zuhaus.png";
import end from '../assets/zuhaus-end.jpeg'
import styled from 'styled-components';
import { useMediaQuery } from "@mui/material";

const StyledLink = styled.a`
  position: relative;
  color: rgb(93, 93, 209);
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  gap: 1rem;
  width: max-content;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    height: 2px;
    background: ${(props) => (props.currentTheme === 'light' ? 'black' : 'white')};
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:hover:after {
    width: 100%;
    left: 0;
  }
`;

function Zuhaus(props) {
    const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
    return (
        <main className={classes.releases}>
            <div className={`${classes.release}`} style={{ color: props.currentTheme === "light" ? "black" : "white" }}>
                <div className={classes.container}>
                    <img src={zuhaus} alt="mindmines" className={classes.img} />
                </div>
                <div className={classes.content}>
                    <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column" }}>
                        <div style={{ marginRight: isNonMobileScreens ? '1.5rem' : "", marginBottom: isNonMobileScreens ? "" : "1rem" }}>
                            <h2 style={{ margin: 0, textAlign: isNonMobileScreens ? "right" : "center", fontSize: "1.7rem", marginTop: '1rem' }}>
                                BRIDGING
                            </h2>
                            <h2
                                style={{
                                    color: "rgb(93, 93, 209)",
                                    textAlign: isNonMobileScreens ? "right" : "center",
                                    margin: 0,
                                    fontSize: "2.5rem",
                                    fontWeight: "bold"
                                }}
                            >
                                CULTURES
                            </h2>
                        </div>

                        <div>
                            <p className={classes.description2}>
                                Germany offers a world of opportunities, and Zuhaus Deutschland
                                is your key to unlocking them. Zuhaus Deutschland is your
                                compass to this vibrant nation.{" "}
                            </p>
                            <p className={classes.description2}>
                                Our platform acts as your trusted ally, guiding you through the
                                labyrinth of expat life in Germany. We offer expert insights on
                                accommodation search, facilitate meaningful interactions with
                                academia, and provide a robust social support system, helping
                                you make Germany your true home away from home.
                            </p>
                        </div>
                    </div>

                    {!isNonMobileScreens && <div style={{ margin: "0 1rem", marginTop: "1rem", flexDirection: isNonMobileScreens ? "" : "column", marginBottom: isNonMobileScreens ? "" : "1rem" }}>
                        <h2 style={{ margin: 0, textAlign: "center", fontSize: "1.7rem" }}>
                            AT HOME
                        </h2>
                        <h2
                            style={{
                                color: "rgb(93, 93, 209)",
                                textAlign: "center",
                                margin: 0,
                                fontSize: "2.5rem",
                                fontWeight: "bold"
                            }}
                        >
                            IN GERMANY
                        </h2>
                    </div>}

                    <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column" }}>
                        <p className={classes.description2} style={{ width: isNonMobileScreens ? "250%" : "" }}>
                            <a
                                href="https://zuhaus.org/unihealth2023"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "white", textDecoration: "none" }}
                            >
                                Zuhaus.org (Zuhaus Deutschland)
                            </a>{" "}
                            is a One-Stop Solution that supports professionals and students feel
                            at home in Germany. The integrated platform builds on aspects of
                            Accommodation, Collaboration, Education and Support (ACES)
                            using the VASE framework given below :{" "}
                            <ul style={{ marginBottom: "0", marginTop: "0.8rem" }}>
                                <li>Verified Accommodation Search Ecosystem</li>
                                <li>Virtual Academia - Student Ecosystem</li>
                                <li>Virtual Advisory & Support Ecosystem</li>
                            </ul>
                        </p>
                        {isNonMobileScreens && <div style={{ margin: "0 1rem", marginTop: "1rem", flexDirection: isNonMobileScreens ? "" : "column" }}>
                            <h2 style={{ margin: 0, textAlign: "center", fontSize: "1.7rem" }}>
                                AT
                            </h2>
                            <h2
                                style={{
                                    color: "rgb(93, 93, 209)",
                                    textAlign: "center",
                                    margin: 0,
                                    fontSize: "2.5rem",
                                    fontWeight: "bold"
                                }}
                            >
                                HOME
                            </h2>
                            <h2 style={{ margin: 0, fontSize: "1.7rem", textAlign: "center" }}>
                                IN
                            </h2>
                            <h2
                                style={{
                                    color: "rgb(93, 93, 209)",
                                    textAlign: "center",
                                    margin: 0,
                                    fontSize: "2.5rem",
                                    fontWeight: "bold"
                                }}
                            >
                                GERMANY
                            </h2>
                        </div>}
                        <div>
                            <p className={classes.description2}>
                                The ZD platform and the Zuhaus social media and messaging city
                                groups work together to cater to the needs of the Expat community in
                                Germany towards building a safe and scam free ecosystem.{" "}
                            </p>
                        </div>
                        {isNonMobileScreens && <div style={{ marginLeft: '1rem', marginTop: "2.5rem" }}>
                            <h2 style={{ margin: 0, textAlign: "center", fontSize: "1.4rem" }}>
                                CELEBRATING
                            </h2>
                            <h2
                                style={{
                                    color: "rgb(93, 93, 209)",
                                    textAlign: "center",
                                    margin: 0,
                                    fontSize: "2.3rem",
                                    fontWeight: "bold"
                                }}
                            >
                                DIVERSITY
                            </h2>
                        </div>}
                    </div>

                    <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column" }}>
                        <div style={{ marginRight: isNonMobileScreens ? '1.7rem' : "", marginBottom: isNonMobileScreens ? "" : "1rem" }}>
                            <h2 style={{ margin: 0, textAlign: isNonMobileScreens ? "right" : "center", fontSize: "1.7rem", marginTop: isNonMobileScreens ? '1rem' : "" }}>
                                THE
                            </h2>
                            <h2
                                style={{
                                    color: "rgb(93, 93, 209)",
                                    textAlign: isNonMobileScreens ? "right" : "center",
                                    margin: 0,
                                    fontSize: "2.5rem",
                                    fontWeight: "bold"
                                }}
                            >
                                PIONEER
                            </h2>
                        </div>
                        <div>
                            <p className={classes.description2}>
                                Presenting Zuhaus Deutschland, the first ever Verified Accommodation
                                Search Ecosystem with legal support and an integrated marketplace
                                for landlords and tenants in Germany.{" "}Zuhaus Deutschland has introduced a Real Estate Verification
                                Accreditation for Germany called D-VIZA (Dokumtenten
                                Verifiezierung) in partnership with international verification
                                agencies to build a verification model for expatriates and students
                                moving to Germany and looking for a home. This is a two - way
                                verification model built using ML to ensure a scam free transaction
                                zone.{" "}
                            </p>
                        </div>

                    </div>
                    <StyledLink
                        href="https://zuhaus.org/"
                        currentTheme={props.currentTheme}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Visit Zuhaus
                        <BsArrowRight />
                    </StyledLink>
                </div>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    {isNonMobileScreens && <div className={classes.line} style={{ backgroundColor: props.currentTheme === "dark" ? "white" : "black" }}></div>}
                    <img src={end} alt="zuhaus" style={{ margin: isNonMobileScreens ? "0 1rem" : "auto", width:"50%" }} />
                    {isNonMobileScreens && <div className={classes.line} style={{ backgroundColor: props.currentTheme === "dark" ? "white" : "black" }}></div>}
                </div>
            </div>
        </main >
    );
}

export default Zuhaus;

// reva