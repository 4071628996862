import { useMediaQuery } from "@mui/material";
import classes from "./Solution.module.css";

const Solution = (props) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
  return (
    <main className={classes.releases}>
      <div className={classes.release} style={{ color: props.currentTheme === "light" ? "black" : "white" }}>
        <div className={classes.content} >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem" }} id="iydabc">
            <h1 className={classes.title}>International Youth Development</h1>
          </div>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column" }}>
            <div style={{ marginRight: isNonMobileScreens?"1.5rem":"", textAlign: isNonMobileScreens ? "right" : "left", marginLeft:isNonMobileScreens?"":"1rem" }}>
              <h2 style={{ margin: 0, fontSize: "1.4rem" }}>OUR</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", fontSize: isNonMobileScreens?"2rem":"2.1rem", fontWeight: "bold" }}>APPROACH</h2>
            </div>
            <div>
              <p className={classes.description} >Our commitment to nurturing the potential of youth transcends borders. We believe in a holistic approach to youth development, encompassing a wide range of topics including cross-cultural understanding, and personal development. </p>
            </div>
          </div>
          <p className={classes.description} style={{ marginBottom: isNonMobileScreens?"0.5rem":"2rem", marginTop: isNonMobileScreens?"1rem":"" }}>Through our interventions, we create immersive learning experiences that foster creativity, critical thinking, and collaboration among participants and fortify their abilities in the following aspects of life: </p>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column-reverse" }}>
            <div style={{ width: isNonMobileScreens?"60%":"" }}>
              <p className={classes.description}><span>Empowerment:</span> We aim to empower youth by providing them with tools to enhance their personal and professional development. We focus on instilling confidence, resilience, and adaptability.</p>
              <p className={classes.description} ><span>Career Guidance:</span> We provide insights into various career paths, offering guidance and mentorship to help participants make informed choices about their academic and professional futures, including Career Transition Coaching that supports individuals in career transitions, be it advancing to higher positions or changing career paths.</p>
          <p className={classes.description}><span>Coping Strategies:</span> We provide guidance and support in developing coping mechanisms to navigate stress, and peer pressure effectively.</p>
            </div>
            <div style={{ marginLeft: isNonMobileScreens?"1.5rem":"1rem", marginTop: "0.3rem", width: isNonMobileScreens?"40%":"90%", marginBottom:isNonMobileScreens?"":"1rem" }}>
              <img src={"https://img.freepik.com/free-photo/youth-culture-young-adult-generation-teenagers_53876-13848.jpg?w=826&t=st=1701464001~exp=1701464601~hmac=7a96cf8d8db89778fa7f34589eb97e9ff1464c55bcf7215f3b6eac28a478778b"} alt="pic" width="100%" style={{ marginTop: "-1rem" }} />
            </div>
          </div>
              <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.2rem"}}>Cross-Cultural Understanding:</span> In a globalized world, cultural competency is crucial, and so is an enviornment of cross-cultural exchange, understanding, tolerance, and appreciation for diversity.</p>
          {/* <p className={classes.description}>professional futures, including Career Transition Coaching that supports individuals in career transitions, be it advancing to higher positions or changing career paths.</p> */}
          <p className={classes.description}><span>Resilience:</span> Helping them develop resilience and adaptability, enabling them to bounce back from setbacks and face challenges confidently.</p>
          <div style={{ display: "flex", marginBottom: isNonMobileScreens?"3rem":"3rem", flexDirection: isNonMobileScreens ? "" : "column-reverse" }}>
            <div>
              <p className={classes.description} >We welcome partnerships and collaborations with organizations, educational institutions, and community groups to co-create and host these workshops. Together, we can provide a platform for young individuals to learn, connect, and grow, contributing positively to their communities and the world at large. Our workshops are designed to be dynamic, impactful, and inspiring, aiming to leave a lasting impression on the participants. </p>
            </div>
            <div style={{ marginLeft: isNonMobileScreens?"1.5rem":"1rem", marginTop: "0.3rem" }}>
              <h2 style={{ margin: 0, textAlign: "left", fontSize: "1.4rem" }}>COLLABORATION</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", textAlign: "left", marginBottom: "0.5rem", fontSize: "2.2rem", fontWeight: "bold" }}>OPPURTUNITIES</h2>
            </div>
          </div>


          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "3rem" }} id='eas'>
            <h1 className={classes.title} >Expat Acclimatization & Safety</h1>
          </div>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column" }}>
            <div style={{ marginRight: isNonMobileScreens?"1.5rem":"", marginTop: "1.5rem", textAlign: isNonMobileScreens ? "right" : "left", marginLeft:isNonMobileScreens?"":"1rem" }}>
              <h2 style={{ margin: 0, fontSize: "1.6rem" }}>OUR</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", marginBottom: "0.5rem", fontSize: isNonMobileScreens?"2rem":"2.1rem", fontWeight: "bold" }}>APPROACH</h2>
            </div>
            <div>
              <p className={classes.description} >Germany is opening its doors to expats and their policies and opportunities are welcoming with many students and professionals making a bee-line for the country, However, one of the first and biggest challenges expats face in Germany is navigating the real estate space and finding a home. The housing shortage and its resultant pressures have led to the rise of scams and frauds The website Zuhaus.org is aimed at providing information for persons relocating to or living in Germany. It is the first ever Verified Accommodation Search Ecosystem with legal support and an integrated marketplace for landlords and tenants in Germany.  </p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column-reverse" }}>
            <div style={{ width: isNonMobileScreens?"62%":"" }}>
              <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Verified Accommodation Search Ecosystem : </span>Zuhaus.org (Zuhaus Deutschland) is an internet portal for International Users built with the objective of providing a safe place for information exchange and transactions in Germany. It is a One-Stop web-based solution for their Education and Accommodation requirements to feel at home in Germany. </p>
              <p className={classes.description} style={{ marginBottom: "0" }}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>D-VIZA (Dokumtenten Verifiezierung): </span>Through Zuhaus Deutschland, we have introduced a Real Estate Verification Accreditation for Germany (REVA)…called D-VIZA (Dokumtenten Verifiezierung) in partnership with international verification agencies to build a verification system for expatriates and students moving to Germany and looking for a home. D-Viza is a two way This is a built using ML to ensure a scam free transaction zone; developed by Zuhaus Deutschland to identify, predict and prevent rental scams in Germany. It has been developed over years of research to ensure safety for both landlords and tenants.</p>
            </div>
            <div style={{ marginLeft: isNonMobileScreens?"1.5rem":"1rem", marginTop: "0.3rem", width: isNonMobileScreens?"40%":"90%", marginBottom:isNonMobileScreens?"":"1rem" }}>
              <img src={"https://img.freepik.com/free-photo/happy-young-professional-successfully-starting-their-careers_1262-14984.jpg?w=740&t=st=1701465490~exp=1701466090~hmac=7e7070493e162a398852c4ce904df94f143b91d3a44edfa257de877e3638b106"} alt="pic" width="100%" />
            </div>
          </div>
          <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Virtual Academia - Student Ecosystem: </span> Those seeking to come to Germany for Higher Studies or other Academic Pursuits can browse information on Universities and Programs, check their eligibility, get access to  government portals that provide authentic information. The Zuhaus platform may host various sessions from universities and related institutions and coaching or advisory sessions by individuals, university sessions by Admissions offices and / or by other third-party experts in the area.</p>
          <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Virtual Advisory & Support Ecosystem:</span> Through our platform, Zuhaus Deutschland, we have provided access to various virtual sessions on various topics ranging from legal issues to admission counselling or language training.  They may reach out for Legal advice and Grievance redressal support on the portal. They may attend online sessions with legal experts who will help with their queries or address their concerns</p>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column-reverse", marginBottom:isNonMobileScreens?"":"3rem" }}>
            <div>
              <p className={classes.description} >We extend an invitation to collaborate with institutions, real estate companies and platforms and student housing bodies across Deutschland to help verify landlords and tenants and create a safe and scam-free environment where expats can feel at home.  </p>
            </div>
            <div style={{ marginLeft: isNonMobileScreens?"1.5rem":"1rem", marginBottom: isNonMobileScreens?"5rem":"" }}>
              <h2 style={{ margin: 0, textAlign: "left", fontSize: "1.3rem" }}>COLLABORATION</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", textAlign: "left", marginBottom: "0.5rem", fontSize: "2.2rem", fontWeight: "bold" }}>OPPURTUNITIES</h2>
            </div>
          </div>


          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "3rem" }} id="sdc">
            <h1 className={classes.title}>Student Development Centre</h1>
          </div>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column" }}>
            <div style={{ marginRight: isNonMobileScreens?"1.5rem":"", textAlign: isNonMobileScreens ? "right" : "left", marginLeft:isNonMobileScreens?"":"1rem" }}>
              <h2 style={{ margin: 0, fontSize: "1.4rem" }}>OUR</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", fontSize: "2rem", fontWeight: "bold" }}>APPROACH</h2>
            </div>
            <div>
              <p className={classes.description} >We offer tailored and comprehensive learning organizational and career guidance services aimed at guiding and empowering students and families to achieve their strategic goals, to overcome personal and environmental challenges, and unlock their full potential.  </p>
            </div>
          </div>
          <p className={classes.description} style={{ marginBottom: isNonMobileScreens?"0.5rem":"1rem", marginTop: isNonMobileScreens?"1.5rem":"" }}>We believe in fostering an environment that encourages self-reflection, skill enhancement, and goal setting. Our coaches are seasoned professionals with equipped to offer valuable insights and practical advice. </p>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column-reverse" }}>
            <div style={{ width: isNonMobileScreens?"60%":"" }}>
              <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Strategic Guidance:</span> We assist in crafting clear business strategies, identifying opportunities, and overcoming obstacles that hinder growth.</p>
              <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Skill Enhancement:</span> Our coaching programs focus on honing specific skill sets crucial for business success, decision-making, communication, and problem-solving.</p>
              <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.3rem"}}>Performance Improvement:</span> We work closely with students to enhance their performance, productivity, and efficiency.</p>
              <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Personalized Development Plans:</span> We develop customized development plans tailored to students to meet their educational needs and career goals. Activities and discussions cantered around personal growth, mental well-being, and emotional intelligence.</p>
            </div>
            <div style={{ marginLeft: isNonMobileScreens?"1.5rem":"1rem", marginTop: "0.3rem", width: isNonMobileScreens?"40%":"90%", marginBottom:isNonMobileScreens?"":"1rem" }}>
              <img src={"https://img.freepik.com/free-photo/diverse-group-people-holding-speech-bubble-concept_53876-26256.jpg?w=740&t=st=1701467005~exp=1701467605~hmac=867de98544bf26e1dce55c24a46328fe7279576396ffa6ba514811bb31586d43"} alt="pic" width="100%" />
            </div>
          </div>
          <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Cross-Cultural Sensitivity:</span> Sessions dedicated to promoting global citizenship, encouraging youngsters to become responsible global citizens.</p>
          <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Mentorship:</span> Participants receive one-on-one mentorship and guidance from experienced coaches, fostering a supportive environment for growth and learning.</p>
          <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Resilience:</span>  Helping them develop resilience and adaptability, enabling them to bounce back from setbacks and face challenges confidently.</p>
          <p className={classes.description}><span style={{fontSize:isNonMobileScreens?"":"1.4rem"}}>Future Ready:</span> Our programs prepare young individuals for the demands of the future, ensuring they are equipped with essential skills and abilities.</p>
          <div style={{ display: "flex", marginBottom: "3rem", flexDirection: isNonMobileScreens ? "" : "column-reverse" }}>
            <div>
              <p className={classes.description} >We extend an invitation to collaborate with institutions aiming to empower and equip young minds with the essential skills, knowledge, and confidence to navigate a dynamic multi-racial society. We offer both student and family coaching sessions, tailored workshops, and ongoing support to individuals and their families. </p>
            </div>
            <div style={{ marginLeft:  isNonMobileScreens?"1.5rem":"1rem", marginTop: "0.3rem" }}>
              <h2 style={{ margin: 0, textAlign: "left", fontSize: "1.4rem" }}>COLLABORATION</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", textAlign: "left", marginBottom: "0.5rem", fontSize: "2.2rem", fontWeight: "bold" }}>OPPURTUNITIES</h2>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem" }} id="bc">
            <h1 className={classes.title}>Buisness Coaching</h1>
          </div>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column" }}>
            <div style={{ marginRight: isNonMobileScreens?"1.5rem":"", textAlign: isNonMobileScreens ? "right" : "left", marginLeft:isNonMobileScreens?"":"1rem" }}>
              <h2 style={{ margin: 0, fontSize: "1.4rem" }}>OUR</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", fontSize: "2rem", fontWeight: "bold" }}>APPROACH</h2>
            </div>
            <div>
              <p className={classes.description} >Our commitment to nurturing the potential of youth transcends borders encompassing a wide range of topics including leadership, entrepreneurshial mindsets, innovation, cross-cultural understanding, and personal development. Our approach to business coaching is rooted in personalized attention. </p>
            </div>
          </div>
          <p className={classes.description} style={{ marginBottom: isNonMobileScreens?"0.5rem":"2rem", marginTop: isNonMobileScreens?"1.5rem":"" }}>Through our initiatives, we create immersive learning experiences that foster creativity, critical thinking, and collaboration among participants and fortify their abilities in the following aspects of life: </p>
          <div style={{ display: "flex", flexDirection: isNonMobileScreens ? "" : "column-reverse" }}>
            <div style={{ width: isNonMobileScreens?"60%":"" }}>
              <p className={classes.description} style={{textAlign:"center"}}><span>Resilience:</span> Helping them develop resilience and adaptability, enabling them to bounce back from setbacks and face challenges confidently.</p>
              <p className={classes.description} style={{textAlign:"center"}}><span>Innovation and Entrepreneurship:</span> Workshops focusing on fostering innovation, creativity, and entrepreneurial mindset among participants.</p>
              <p className={classes.description} style={{textAlign:"center"}}><span>Executive Coaching:</span> Offering targeted coaching for executives to enhance their strategic thinking, communication, and management abilities.</p>
            </div>
            <div style={{ marginLeft: isNonMobileScreens?"1.5rem":"1rem", marginTop: "0rem", width: isNonMobileScreens?"40%":"90%", marginBottom:isNonMobileScreens?"":"1rem" }}>
              <img src={"https://img.freepik.com/free-photo/youth-culture-young-adult-generation-teenagers_53876-13848.jpg?w=826&t=st=1701464001~exp=1701464601~hmac=7a96cf8d8db89778fa7f34589eb97e9ff1464c55bcf7215f3b6eac28a478778b"} alt="pic" width="100%" style={{ marginTop: "-1rem" }} />
            </div>
          </div>
          <div style={{ display: "flex", marginBottom: "3rem", flexDirection: isNonMobileScreens ? "" : "column-reverse", marginTop:"1rem" }}>
            <div>
              <p className={classes.description} >We welcome partnerships and collaborations with organizations, educational institutions, and community groups to create, connect, contribute and grow in thier work enviornments. Together, we can provide a platform for young individuals to learn, connect, and grow, contributing positively to their communities and the world at large. Our workshops are designed to be dynamic, impactful, and inspiring, aiming to leave a lasting impression on the participants. </p>
            </div>
            <div style={{ marginLeft: isNonMobileScreens?"1.5rem":"1rem", marginTop: "0.3rem" }}>
              <h2 style={{ margin: 0, textAlign: "left", fontSize: "1.4rem" }}>COLLABORATION</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", textAlign: "left", marginBottom: "0.5rem", fontSize: "2.2rem", fontWeight: "bold" }}>OPPURTUNITIES</h2>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Solution;