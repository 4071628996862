import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import classes from "./Counter.module.css";
import { useMediaQuery } from "@mui/material";

const Counter = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
  const [isVisible, setIsVisible] = useState(isNonMobileScreens ? false : true);

  const onVisibilityChange = (check) => {
    if (check) {
      setIsVisible(true);
    }
  };

  return (
    <div style={{ paddingTop: isNonMobileScreens ? "7rem" : "2rem" }} id="about">
      <main className={classes.container}>
        <h3 className={classes.heading}>
          <VisibilitySensor onChange={onVisibilityChange}>
            <div className={classes.wrapper}>
              <div className={classes.box}>
                <span className={classes.round}><CountUp end={isVisible ? 35000 : 0} duration={1} />
                  <span className={classes.color}>YOUTH</span></span>
              </div>
              <h1 className={classes.orange}>FROM</h1>
              <div className={classes.box}>
                <span className={classes.round}><CountUp end={isVisible ? 130 : 0} duration={1} />
                  <span className={classes.color}>COUNTRIES</span></span>
              </div>
              <h1 className={classes.orange}>ACROSS</h1>
              <div className={classes.box}>
                <span className={classes.round}><CountUp end={isVisible ? 25 : 0} duration={1} />
                  <span className={classes.color}>GERMAN CITIES</span>
                </span>
              </div>
            </div>
          </VisibilitySensor>
        </h3>
      </main>
    </div>
  );
};

export default Counter;