import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { ImSun } from "react-icons/im";
import { BsFillMoonFill } from "react-icons/bs";
import logo from "../assets/SOLUTIONS.png";
import bglogo from "../assets/dark-logo.png";
import zuhausLogo from "../assets/zuhaus logo.png";
import mindminesLogo from "../assets/mindmines-logo.png";
import classes from "./Navbar.module.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const NavBar = (props) => {
  const [navState, setNavState] = useState(false);
  const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
  const navigate = useNavigate();

  const brandClasses = `${classes.brand} ${
    props.currentTheme === "light" ? classes.blur : ""
  }`;
  const linkClasses = `${classes.links} ${
    props.currentTheme === "light" ? classes.light : ""
  }`;
  const toggleContainerClasses = `${classes["toggle-container"]} ${
    props.currentTheme === "light" ? classes.toggleLight : ""
  }`;

  return (
    <nav className={classes.container}>
      <div className={classes["brand-container"]}>
        <div className={brandClasses}>
          <img
            src={props.currentTheme === "light" ? logo : bglogo}
            alt="logo"
            style={{
              cursor: "pointer",
            }}
            width={isNonMobileScreens ? "100%" : "120%"}
            onClick={() => navigate("/")}
          />
        </div>
        <div className={toggleContainerClasses}>
          <div className={classes.toggle}>
            {navState ? (
              <MdClose onClick={() => setNavState(false)} />
            ) : (
              <GiHamburgerMenu onClick={() => setNavState(true)} />
            )}
          </div>
          <div className={classes.mode} onClick={props.changeTheme}>
            {props.currentTheme === "dark" ? (
              <ImSun className={classes.light} />
            ) : (
              <BsFillMoonFill className={classes.dark} />
            )}
          </div>
        </div>
      </div>
      <div
        className={`${classes["links-container"]} ${
          navState ? classes["nav-visible"] : ""
        }`}
      >
        <ul className={linkClasses}>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "-2rem",
            }}
          >
            <a
              href="#about"
              onClick={() => navigate("/")}
              style={{
                marginRight: "2.5rem",
                color: props.currentTheme === "dark" ? "#cccccc" : "black",
              }}
              className={classes.anchor}
            >
              ABOUT
            </a>
          </li>
          <li>
            <div className={classes.dropdown}>
              <button
                className={classes.dropbtn}
                style={{ color: props.currentTheme === "light" ? "black" : "" }}
                onClick={() => navigate("/solutions")}
              >
                SOLUTIONS
              </button>
              <div className={classes["dropdown-content"]}>
                <a href="/solutions" style={{ color: props.currentTheme === "light" ? "black" : "" }}>International Youth Development</a>
                <a href="#eas" onClick={() => navigate("/solutions")} style={{ color: props.currentTheme === "light" ? "black" : "" }}>
                  Expat Acclimatization & Safety
                </a>
                <a href="#sdc" onClick={() => navigate("/solutions")} style={{ color: props.currentTheme === "light" ? "black" : "" }}>
                  Student Development Center
                </a>
                <a href="#bc" onClick={() => navigate("/solutions")} style={{ color: props.currentTheme === "light" ? "black" : "" }}>
                  Business Coaching
                </a>
              </div>
            </div>
          </li>
          <li>
            <div className={classes.dropdown}>
              <button
                className={classes.dropbtn}
                style={{ color: props.currentTheme === "light" ? "black" : "" }}
              >
                VENTURES
              </button>
              <div className={classes["dropdown-content"]}>
                <a href="/zuhaus">
                  <img src={zuhausLogo} alt="zuhaus" width="130rem" />
                </a>
                <a href="/mindmines">
                  <img src={mindminesLogo} alt="zuhaus" width="130rem" />
                </a>
              </div>
            </div>
          </li>
          <li>
            <a
              style={{
                color: props.currentTheme === "dark" ? "#cccccc" : "black",
              }}
              href="#contact"
              onClick={() => navigate("/")}
              className={classes.anchor}
            >
              CONTACT
            </a>
          </li>
          <li
            onClick={props.changeTheme}
            style={{ marginRight: "1rem", cursor: "pointer" }}
          >
            {props.currentTheme === "dark" ? (
              <ImSun className={classes.light} />
            ) : (
              <BsFillMoonFill className={classes.dark} />
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
