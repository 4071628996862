import React, { useState } from 'react';
import classes from './Contact.module.css';
import contact from '../assets/contact.png';
import { Button, Box, useMediaQuery, ThemeProvider, createTheme } from "@mui/material";
import TextField from '@mui/material/TextField';
import axios from 'axios';

// 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

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

function Contact(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const isNonMobileScreens = useMediaQuery("(min-width:1001px)");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // eslint-disable-next-line
            const response = await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: {
                        name: name,
                        email: email
                    },
                    to: [{
                        email: 'support@westinbridge.com',
                        name: 'Support Team'
                    }],
                    subject: subject,
                    htmlContent: `<p>Name: ${name}</p><p>Email: ${email}</p><p>Subject: ${subject}</p><p>Message: ${message}</p>`,
                },
                {
                    headers: {
                        'api-key': process.env.REACT_APP_API_KEY,
                        'content-type': 'application/json'
                    }
                }
            );

            window.alert("Email sent successfully");
        } catch (error) {
            console.error('Error sending email:', error);
            window.alert("Error sending email");
        }
        setEmail("");
        setMessage("");
        setName("");
        setSubject("");
    };
    return (
        <div className={classes.container} id='contact'>
            <div className={classes.top} style={{ color: props.currentTheme === "light" ? "black" : "white" }}>CONTACT US</div>
            <div className={classes.main}>
                <ThemeProvider
                    theme={props.currentTheme === "dark" ? darkTheme : lightTheme}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: isNonMobileScreens ? "45%" : "80%",
                            color: "white",
                            borderRadius: "1.5rem",
                            p: 1
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                maxWidth: 600,
                                mx: "auto",
                                p: 2,
                                borderRadius: "12px",
                            }}
                        >
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    margin="normal"
                                    required
                                    size='medium'
                                    InputProps={{
                                        style: {
                                            color: "white",
                                            // borderColor:"white"
                                        },
                                    }}
                                    sx={{ color: "white", borderColor: "white" }}
                                />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    margin="normal"
                                    required
                                    type="email"
                                    size='medium'
                                />
                                <TextField
                                    fullWidth
                                    label="Subject"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    margin="normal"
                                    required
                                    size='medium'
                                />
                                <TextField
                                    fullWidth
                                    label="Message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    margin="normal"
                                    required
                                    multiline
                                    rows={4}
                                />
                                <Button
                                    fullWidth
                                    type="submit"
                                    sx={{
                                        mt: 2,
                                        backgroundColor: "#6a7e96",
                                        pt: 2,
                                        pb: 2,
                                        color: "#fff",
                                        borderRadius: "10px",
                                        "&:hover": {
                                            backgroundColor: "#44546a",
                                        },
                                    }}
                                >
                                    Submit
                                </Button>
                            </form>
                        </Box>
                    </Box>
                </ThemeProvider>
                <img src={contact} width="45%" alt='contact' />
            </div>
        </div >
    )
}

export default Contact