import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import classes from "./ScrollToTop.module.css";

const ScrollToTop = props => {
  const [visible, setVisible] = useState(false);
  const [link, setLink] = useState("#ventures");
  window.addEventListener("scroll", () => {
    if(window.pageYOffset <= 200){
      setLink("#ventures");
      setVisible(false);
    }
    else if (window.pageYOffset > 200 && window.pageYOffset <= 1050) {
      setLink("#about");
      setVisible(false);
    }
    else if (window.pageYOffset > 1050 && window.pageYOffset <= 1900) {
      setLink("#mission");
      setVisible(false);
    }
    else if (window.pageYOffset > 1900 && window.pageYOffset <= 2900) {
      setLink("#contact");
      setVisible(false);
    }
    else if (window.pageYOffset > 2900) setVisible(true);

  });
  const scrollToTopClasses = `${classes.scrollToTop} ${props.currentTheme === "light" ? classes.light : ""}`;
  return (
    <div className={`${scrollToTopClasses} ${visible ? classes.visible : classes.visible}`}>
      {/* eslint-disable-next-line */}
      {visible && <a href="#">
        <BsChevronUp />
      </a>}
      {!visible && <a href={link}>
        <BsChevronDown />
      </a>}
    </div>
  );
}

export default ScrollToTop;

// ventures - window.pageYOffset > 600
// about - window.pageYOffset > 1650
// window.pageYOffset > 2350