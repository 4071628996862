import classes from "./Cards.module.css";

const Card = props => {
  const cardClasses = `${classes.card} ${props.currentTheme === "light" ? classes.lightCard : ""}`;
  const cardDetailsClasses = `${classes["card-details"]} ${props.currentTheme === "light" ? classes.lightDetails : ""}`;
  const cardSubDetailsClasses = `${classes["card-sub-details"]} ${props.currentTheme === "light" ? classes.lightSubDetails : ""}`;
  return (
    <div className={cardClasses}>
      <div className={classes["card-image"]}>
        <img
          style={{ height: props.team ? "12rem" : "12.5rem", width: props.team ? "50%" : "100%", borderTopLeftRadius: props.team ? "" : "19px", borderTopRightRadius: props.team ? "" : "19px", backgroundColor:"white" }}
          src={props.image}
          alt={props.title}
        />

      </div>
      <div className={classes["card-content"]} style={{ gap: props.team ? "0.5rem" : "" }}>
        <div className={cardDetailsClasses}>
          <h4 className={classes["card-title"]}>{props.title}</h4>
        </div>
        <div className={cardSubDetailsClasses}>
          <span>{props.desc}</span>
        </div>
      </div>
    </div>
  );
}

export default Card;