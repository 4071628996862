import classes from "./Team.module.css";
import { MdOutgoingMail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";

const darkBorder = {
  borderTop: "0.1rem solid white",
  borderBottom: "0.1rem solid white",
};

const lightBorder = {
  borderTop: "0.1rem solid black",
  borderBottom: "0.1rem solid black",
};

const Team = (props) => {
  const titleClasses = `${classes.title} ${
    props.currentTheme === "light" ? classes.lightTitle : ""
  }`;
  return (
    <main
      className={classes["super-rare"]}
      style={
        props.data[1].title === "Dr. Anupama Varma"
          ? props.currentTheme === "light"
            ? lightBorder
            : darkBorder
          : {
              borderBottom:
                props.currentTheme === "dark"
                  ? "0.1rem solid white"
                  : "0.1rem solid black",
            }
      }
    >
      <div className={classes["title-container"]}>
        <h2 className={titleClasses}>MEET OUR TEAM</h2>
      </div>
      <div className={classes.cards}>
        {props.data.map((item, index) => {
          return (
            <div className={classes.team} key={index}>
              <img
                src={item.image}
                alt={item.title}
                className={classes.img}
                style={{
                  width:
                    item.title === "Ass.lur. Stephan Hartmann" ? "14.5rem" : "",
                }}
              />
              {!props.zuhaus && (
                <h2
                  style={{
                    color: props.currentTheme === "light" ? "black" : "white",
                  }}
                >
                  {item.title}
                </h2>
              )}
              {props.zuhaus && (
                <h4
                  style={{
                    color: props.currentTheme === "light" ? "black" : "white",
                  }}
                >
                  {item.title}
                </h4>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a href={`mailto:${item.mail}`}>
                    <MdOutgoingMail
                      style={{
                        color:
                          props.currentTheme === "light" ? "black" : "white",
                        fontSize: "2rem",
                        marginRight: "0rem",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                  <a
                    href={`mailto:${item.mail}`}
                    style={{
                      color: props.currentTheme === "light" ? "black" : "white",
                    }}
                    className={classes.mail}
                  >
                    {item.mail}
                  </a>
                </div>
                {item.linkedin && (
                  <a
                    style={{ textAlign: "center" }}
                    href={item.linkedin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedin
                      style={{
                        color:
                          props.currentTheme === "light" ? "black" : "white",
                        fontSize: "1.5rem",
                        marginTop: "0.2rem",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default Team;
