import { useState, useEffect } from "react";
import ScrollReveal from "scrollreveal";
import Home from "./pages/Home";
import NavBar from "./components/Navbar";
import classes from "./App.module.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Mindmines from "./pages/Mindmines";
import Zuhaus from "./pages/Zuhaus";
import Solutions from "./pages/Solutions";
import Privacy from "./pages/Privacy";

const App = () => {
  const [theme, setTheme] = useState("dark");
  const changeTheme = () => {
    theme === "dark" ? setTheme("light") : setTheme("dark");
  };

  useEffect(() => {
    const sr = ScrollReveal({
      origin: "bottom",
      distance: "80px",
      duration: 2000,
      reset: false
    });
    sr.reveal(`nav, main, footer`, { interval: 500 });
  }, []);

  const appClasses = `${classes["app-container"]} ${theme === "light" ? classes.light : ""}`;

  return (
    <div className={appClasses}>
      <BrowserRouter>
        <NavBar changeTheme={changeTheme} currentTheme={theme} />
        <Routes>
          <Route path="/" element={<Home currentTheme={theme} />} />
          <Route path="/mindmines" element={<Mindmines currentTheme={theme} />} />
          <Route path="/zuhaus" element={<Zuhaus currentTheme={theme} />} />
          <Route path="/solutions" element={<Solutions currentTheme={theme} />} />
          <Route path="/privacypolicy" element={<Privacy currentTheme={theme} />} />
        </Routes>
      </BrowserRouter>


      {/* 
      <Release currentTheme={theme} />
      <Team currentTheme={theme} />
 */}
    </div>
  );
}

export default App;