import { BsArrowRight } from "react-icons/bs";
import mindmines from '../assets/mindmines.jpeg';
import classes from "./Mindmines.module.css";
import styled from 'styled-components';
import { useMediaQuery } from "@mui/material";

const StyledLink = styled.a`
  position: relative;
  color: rgb(93, 93, 209);
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  gap: 1rem;
  width: max-content;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    height: 2px;
    background: ${(props) => (props.currentTheme === 'light' ? 'black' : 'white')};
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:hover:after {
    width: 100%;
    left: 0;
  }
`;

const Mindmines = (props) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
  return (
    <main className={classes.releases}>
      <div className={classes.release} style={{ color: props.currentTheme === "light" ? "black" : "white" }}>
        <div className={classes.container}>
          <img className={classes.img} src={mindmines} alt='mindmines' />
        </div>
        <div className={classes.content} >
          <div style={{ display: "flex", marginBottom: "0.5rem", flexDirection: isNonMobileScreens ? "" : "column" }}>
            <div style={{ marginRight: isNonMobileScreens ? "1.5rem" : "", textAlign: isNonMobileScreens ? "right" : "center", marginBottom: isNonMobileScreens ? "" : "1rem" }}>
              <h2 style={{ margin: 0, fontSize: "1.4rem" }}>WHERE</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", marginBottom: "0.5rem", fontSize: isNonMobileScreens ? "2.2rem" : "2.5rem", fontWeight: "bold" }}>YOUNG</h2>
              <h2 style={{ margin: 0, fontSize: "1.4rem" }}>EXPATS</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", margin: 0, fontSize: isNonMobileScreens ? "2.2rem" : "2.5rem", fontWeight: "bold" }}>FLOURISH</h2>
            </div>
            <div>
              <p className={classes.description}>MindMines is our digital haven for young expatriates. Relocating to a new country is an exhilarating yet challenging experience. Our platform provides a lifeline of support for these youngsters, offering guidance on settling in new places and coping with the diverse range of learning and development issues they encounter. </p>
              <p className={classes.description}>At MindMines, we celebrate diversity, providing a nurturing environment for these young minds to find their path, make friends, and unlock their potential. We believe in empowering the youth because they are the architects of the future. </p>
            </div>
          </div>
          {/* <p className={classes.description} style={{ marginBottom: isNonMobileScreens ? "2rem" : "1rem" }}>MindMines is your guiding light through the challenges of being a young expatriate. We provide resources and support for settling into new places and coping with a wide array of learning and development issues. Our platform is a celebration of diversity and a nurturing environment for young minds to connect, make friends, and realize their full potential. We're dedicated to empowering the youth because we believe they are the architects of the future. </p> */}
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0.5rem", marginBottom: isNonMobileScreens ? "2rem" : "1rem", flexDirection: isNonMobileScreens ? "" : "column" }}>
            <div style={{ width: isNonMobileScreens ? "30%" : "", }}>
              <h2 style={{ margin: 0, textAlign: "center", fontSize: "1.2rem" }}>EMPOWERING</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", textAlign: "center", margin: 0, fontSize: isNonMobileScreens ? "2.2rem" : "2.5rem", fontWeight: "bold", marginBottom: isNonMobileScreens ? "" : "1rem" }}>YOUTH</h2>
              <p className={classes.description}>MindMines is your guiding light through the challenges of being a young expatriate. We provide resources and support for settling into new places and coping with a wide array of learning and development issues. Our platform is a celebration of diversity and a nurturing environment for young minds to connect, make friends, and realize their full potential.  </p>
            </div>
            <div style={{ width: isNonMobileScreens ? "30%" : "", marginBottom: "1rem" }}>
              <h2 style={{ margin: 0, textAlign: "center", fontSize: "1.2rem" }}>SHAPING</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", textAlign: "center", margin: 0, fontSize: isNonMobileScreens ? "2.2rem" : "2.5rem", fontWeight: "bold", marginBottom: isNonMobileScreens ? "" : "1rem" }}>FUTURES</h2>
              <p className={classes.description}>Through a curated array of programs, experiences, and resources, we aspire to be a catalyst in shaping individuals who are open-minded, adaptable and equipped with the mindset necessary to thrive in an ever-evolving global landscape. We assist youth in setting realistic short-term and long-term goals. We help instil a Growth mindset.  </p>
            </div>
            <div style={{ width: isNonMobileScreens ? "33%" : "" }}>
              <h2 style={{ margin: 0, textAlign: "center", fontSize: "1.2rem" }}>UNLOCKING</h2>
              <h2 style={{ color: "rgb(93, 93, 209)", textAlign: "center", margin: 0, fontSize: isNonMobileScreens ? "2.2rem" : "2.5rem", fontWeight: "bold", marginBottom: isNonMobileScreens ? "" : "1rem" }}>POTENTIAL</h2>
              <p className={classes.description}>Mindmines aims to create an environment that not only exposes individuals to a diverse array of cultures and industries but also nurtures a mindset that thrives on continuous learning, resilience, and adaptability. Through Mindmines, we provide exposure to diverse cultures, industries, and perspectives. Exposure to new experiences broadens their horizons and fosters adaptability.  </p>
            </div>
          </div>
          <StyledLink
            href="https://mindmines.vercel.app/"
            currentTheme={props.currentTheme}
            target="_blank"
            rel="noreferrer"
          >
            Visit MindMines
            <BsArrowRight />
          </StyledLink>
        </div>
      </div>
    </main>
  );
}

export default Mindmines;