import { Fragment } from 'react';
import Solution from '../components/Solution';
import Footer from '../components/UI/Footer';

function Solutions(props) {
    return (
        <Fragment>
            <Solution currentTheme={props.currentTheme}/>
            <Footer currentTheme={props.currentTheme}/>
        </Fragment>
    );
}

export default Solutions