import { useMediaQuery } from '@mui/material';
import carousel1 from '../../assets/carousel1.jpg';
import carousel2 from '../../assets/carousel2.jpg';
import carousel3 from '../../assets/carousel3.avif';
import carousel4 from '../../assets/carousel4.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';

function ImageCarousel(props) {
    const isNonMobileScreens = useMediaQuery("(min-width:1001px)");
    return (
        <>
            <div className='pt-3' style={{ width: "100%", margin: "auto" }}>
                <Carousel>
                    <Carousel.Item>
                        <img style={{ maxHeight: isNonMobileScreens ? "74vh" : "27.5vh", objectFit: "cover", borderRadius: "20px", opacity: props.currentTheme === "dark" ? "0.9" : "1", filter: 'brighness(120%)' }}
                            className="d-block w-100"
                            src={carousel1}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h1>BRIDGING CULTURES</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={carousel2}
                            alt="Second slide"
                            style={{ maxHeight: isNonMobileScreens ? "74vh" : "27.5vh", objectFit: "cover", borderRadius: "20px", opacity: props.currentTheme === "dark" ? "0.9" : "1" }}
                        />

                        <Carousel.Caption>
                            <h1>EMPOWERING YOUTH </h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={carousel3}
                            alt="Third slide"
                            style={{ maxHeight: isNonMobileScreens ? "74vh" : "27.5vh", objectFit: "cover", borderRadius: "20px", opacity: props.currentTheme === "dark" ? "0.9" : "1" }}
                        />

                        <Carousel.Caption>
                            <h1>UNLOCKING POTENTIAL</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={carousel4}
                            alt="Third slide"
                            style={{ maxHeight: isNonMobileScreens ? "74vh" : "27.5vh", objectFit: "", borderRadius: "20px", opacity: props.currentTheme === "dark" ? "0.9" : "1", height: "50rem", filter: 'brightness(120%)' }}
                        />

                        <Carousel.Caption>
                            <h1>SHAPING FUTURES</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </>
    );
}

export default ImageCarousel;