import React, { Fragment } from 'react'
import Footer from '../components/UI/Footer'
import Policy from '../components/Policy'

function Privacy(props) {
  return (
    <Fragment>
        <Policy currentTheme={props.currentTheme}/>
      <Footer currentTheme={props.currentTheme}/>
    </Fragment>
  )
}

export default Privacy