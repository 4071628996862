import React, { Fragment } from 'react';
import super1 from "../assets/rekha.png";
// import super2 from "../assets/julia.png";
import super3 from "../assets/stephan.png";
import dhiren from "../assets/dhiren.png";
import ZuhausComponent from '../components/Zuhaus';
import Team from '../components/Team';
import Footer from '../components/UI/Footer';

const data = [
  {
    image: super1,
    title: "Dr. Rekha Singh",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    mail: "director@westinbridge.com",
    linkedin: "https://www.linkedin.com/in/rekha---singh"
  },
  // {
  //   image: super2,
  //   title: "Frau. Julia Petry",
  //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
  //   mail: "outreach@zuhaus.org"
  // },
  {
    image: super3,
    title: "Ass.lur. Stephan Hartmann",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    mail: "legal@zuhaus.org"
  },
  {
    image: dhiren,
    title: "Mr. Dhiren Singh",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    mail: "director@westinbridge.com",
    linkedin: "https://www.linkedin.com/in/dhiren-singh-6b8400b/"
  },
];

function Zuhaus(props) {
  return (
    <Fragment>
      <ZuhausComponent currentTheme={props.currentTheme} />
      <Team currentTheme={props.currentTheme} data={data} zuhaus="true" />
      <Footer currentTheme={props.currentTheme} />
    </Fragment>
  )
}

export default Zuhaus;