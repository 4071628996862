import { useMediaQuery } from "@mui/material";
import classes from "./MissionValues.module.css";

const arr = [
  {
    heading: 'Diversity:',
    description: 'We celebrate the richness of human diversity, embracing different cultures, perspectives, and backgrounds.'
  },
  {
    heading: 'Trust:',
    description: 'Trust forms the core of our relationships - trust in the communities we create, the value we deliver, and the future we envision.'
  },
  {
    heading: 'Empowerment:',
    description: 'We are dedicated to empowering the youth and helping them unlock their potential.'
  },
  {
    heading: 'Community:',
    description: 'We believe in building a global community where people connect, support, and uplift each other.'
  },
  {
    heading: 'Innovation:',
    description: 'We are driven by innovation, consistently seeking new and creative solutions to cross-cultural challenges.'
  }
];


const MissionValues = (props) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1001px)");

  const contentClasses = `${classes.content} ${props.currentTheme === "light" ? classes.lightContent : ""
    }`;
  const titleClasses = `${classes.title} ${props.currentTheme === "light" ? classes.lightTitle : ""
    }`;
  const descriptionClasses = `${classes.description} ${props.currentTheme === "light" ? classes.lightDescription : ""
    }`;

  return (
    <main className={classes.like} id="mission">
      <div className={classes.container}>
        <div className={contentClasses}>

          <h2 className={titleClasses}>Our Mission</h2>
          <p className={descriptionClasses}>
            At Westinbridge Consulting Private Limited, our mission is to create a global youth ecosystem 
            to support youngsters who wish to explore and build their life overseas.  We believe that our 
            world is enriched by its diversity, and our purpose is to nurture this diversity by attempting 
            to bridge boundaries through collaboration and support.  We aim to guide young minds towards 
            meaningful futures and help expatriates feel at home, wherever they are in the world.
          </p>
          <img width="100%" height={isNonMobileScreens?"340rem":"auto"} src="https://img.freepik.com/free-photo/diverse-equality-gender-innovation-management-concept_53876-21397.jpg?w=740&t=st=1702120139~exp=1702120739~hmac=b738efdd54298a0e958688677ade9c31af805923b2a54d4efdf85bbd6e12bf51" alt="mission" />
        </div>
        <div className={contentClasses}>

          <h2 className={titleClasses}>Our Values</h2>
          {arr.map((item, index) => {
            return <h4 style={{ color: '#ff8139', fontSize: '1.5rem' }} key={index} >{item.heading} <p style={{
              color: props.currentTheme === 'dark' ? '#cccccc' : 'black', fontSize: '1.1rem', letterSpacing: isNonMobileScreens?'0.1rem':"",
              lineHeight: '1.5rem'
            }}>{item.description}</p></h4>
          })}
        </div>
      </div>
    </main>
  );
};

export default MissionValues;