import classes from "./Ventures.module.css";
import mindmines from '../assets/mindmines.jpeg';
import zuhaus from '../assets/zuhaus.png';

const Ventures = props => {
  return (
    <main className={classes.home} id="ventures">
      <div className={classes.container}>
        <h3 className={classes.heading} style={{ color: props.currentTheme === "light" ? "black" : "white" }}>OUR VENTURES</h3>
        <a href="/mindmines" className={classes.mind}><img src={mindmines} alt="mindmines"  /></a>
      </div>
      <a href="/zuhaus" className={classes.zuhaus}><img src={zuhaus} alt="zuhaus"  /></a>
    </main>
  );
}

export default Ventures;