import bglogo from "../../assets/dark-logo.png";
import logo from "../../assets/SOLUTIONS.png";
// import { BsFacebook, BsTwitter, BsInstagram, BsLinkedin } from "react-icons/bs";
import classes from "./Footer.module.css";
import { useNavigate } from "react-router-dom";

const links = [
  {
    title: "About",
    data: ["About", "Privacy Policy"],
    click: ["#about", "/privacypolicy"],
  },
  {
    title: "Ventures",
    data: ["Zuhaus", "Mindmines"],
    click: ["/zuhaus", "/mindmines"],
  },
  {
    title: "Contact",
    data: ["Press", "Support"],
    click: ["#contact", "#contact"],
  },
  {
    title: "Social",
    data: ["Linkedin"],
    click: ["https://linkedin.com/",],
  },
];

// const socialLink = [
//   <BsFacebook />,
//   <BsTwitter />,
//   <BsInstagram />,
//   <BsLinkedin />,
// ];

const Footer = props => {
  const navigate = useNavigate();
  const upperClasses = `${classes.upper} ${props.currentTheme === "light" ? classes.lightUpper : ""}`;
  const lowerClasses = `${classes.lower} ${props.currentTheme === "light" ? classes.lightLower : ""}`;
  const linkClasses = `${classes.link} ${props.currentTheme === "light" ? classes.lightLink : ""}`;

  return (
    <footer className={classes.footer}>
      <div className={upperClasses}>
        <div className={classes["brand-container"]} style={{color: props.currentTheme === "dark" ? "#ffffff" : "black"}}>
          <div className={classes.brand}>
            <img src={props.currentTheme === "light" ? logo : bglogo} alt="logo" width="80%" />
          </div>
          <div>
            <p>Bridging Cultures | Empowering Youth</p>
            <p>Shaping Futures | Unlocking Potential</p>
          </div>
          {/* Social media icons */}
          {/* <ul style={{ paddingLeft: 0, cursor: "pointer" }}>
            {socialLink.map((link, index) => (
              <li key={index}>{link}</li>
            ))}
          </ul> */}
        </div>
        <div className={classes.links}>
          {links.map(({ title, data }, index) => {
            return (
              <div className={linkClasses} key={index}>
                <h4 style={{textAlign:"left"}}>{title}</h4>
                <ul style={{ paddingLeft: 0, cursor: "pointer", textAlign:"left" }}>
                  {data.map((link, index2) => (
                    <li onClick={() => navigate('/')} key={index2} style={{width:"max-content"}}>
                      <a href={links[index].click[index2]} className={classes.alink} style={{color: props.currentTheme === "dark" ? "#ffffff" : "black"}}>{link}</a>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
      <div className={lowerClasses}>
        <span style={{color: props.currentTheme === "dark" ? "#ffffff" : "black"}}>&copy; Copyright 2023 Westinbridge</span>
        {/* <span style={{color: props.currentTheme === "dark" ? "#ffffff" : "black"}}>Launching November 2023</span> */}
      </div>
    </footer>
  );
}

export default Footer;