import { Fragment } from 'react';
import ImageCarousel from '../components/UI/Carousel';
import Counter from '../components/Counter';
import Journey from '../components/Journey';
import MissionValues from '../components/MissionValues';
import Ventures from '../components/Ventures';
import Contact from '../components/Contact';
import Footer from '../components/UI/Footer';
import ScrollToTop from '../components/ScrollToTop';

function Home(props) {
    return (
        <Fragment>
            <ScrollToTop currentTheme={props.currentTheme} />
            <ImageCarousel currentTheme={props.currentTheme} />
            <Ventures currentTheme={props.currentTheme} />
            <Counter />
            <Journey currentTheme={props.currentTheme} />
            <MissionValues currentTheme={props.currentTheme} />
            <Contact currentTheme={props.currentTheme} />
            <Footer currentTheme={props.currentTheme} />
        </Fragment>
    )
}

export default Home